import React from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import { Heading1 } from '../components/Typography';
import Section from '../components/Section';
import SEO from '../components/seo';
import TeamCard from '../components/TeamCard/TeamCard';
import Paula from '../images/paula-jacobs2.jpg';
import Simon from '../images/simon-jacobs.jpg';
import Alyn from '../images/alyn-lewis.jpg';
import Paul from '../images/paul-kelly.jpg';
import Ben from '../images/ben-quinn.jpg';
import Bethan from '../images/bethan-lewis.jpg';
import Sarah from '../images/sarah-haley.jpg';
import styled from 'styled-components';

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const teamData = [
  {
    name: 'Paula Jacobs BA (Hons)',
    title: 'Company Director',
    img: Paula,
  },
  {
    name: 'Simon Jacobs BSc',
    title: 'Managing Director',
    img: Simon,
  },
  {
    name: 'Alyn Lewis IRRV (Dip)',
    title: 'Client Development Director',
    img: Alyn,
  },
  {
    name: 'Paul Kelly FIRRV',
    title: 'Client Services Director',
    img: Paul,
  },
  {
    name: 'Ben Quinn BSc (Hons)',
    title: 'Technology Director',
    img: Ben,
  },
  {
    name: 'Sarah Haley',
    title: 'Operations Director',
    img: Sarah,
  },
  {
    name: 'Bethan Lewis Tech IRRV',
    title: 'Head of Public Sector Procurement',
    img: Bethan,
  },
];

const Team = () => {
	const teamCards = teamData.map((data) => (
		<TeamCard key={data.name} data={data} />
	));
	return (
		<>
			<SEO
				title='Meet the team'
				description={`
                Jacobs are the leading Debt Recovery and Enforcement Services provider in the United Kingdom, with over 57 years experience working with local authorities across the country.
                `}
			/>
			<HeroSection>
				<Heading1 marginTop='120px'>The Team</Heading1>
			</HeroSection>
			<Section
				backgroundColor='white'
				paddingTop='80px'
				paddingBottom='200px'
			>
				<Heading1 fontSize='2rem' marginBottom='52px'>
					Senior Management
				</Heading1>

				<Grid>{teamCards}</Grid>
			</Section>
		</>
	);
};

export default Team;
