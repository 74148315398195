import React from 'react';
import styled from 'styled-components';

const Name = styled.h3`
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 8px;
    color: #444444;
`;

const Title = styled.h4`
    font-size: 0.8rem;
    font-weight: 500;
    color: hsla(0, 0%, 0%, 0.5);
`;

const Image = styled.img`
    max-width: 100%;
`;

const Container = styled.div`
    @media screen and (min-width: 910px) {
        width: calc(100% / 4);
        padding: 16px;
    }
    @media screen and (max-width: 910px) {
        width: calc(100% / 3);
        padding: 16px;
    }
    @media screen and (max-width: 810px) {
        width: calc(100% / 2);
        padding: 16px;
    }
    @media screen and (max-width: 476px) {
        width: 100%;
        padding: 0px;
    }
`;

function TeamCard({ data }) {
    return (
        <Container>
            <Image src={data.img} alt={data.name} />
            <Name>{data.name}</Name>
            <Title>{data.title}</Title>
        </Container>
    );
}

export default TeamCard;
